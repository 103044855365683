import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "./index/hero-section"
import imgHomeSectionOne from "../assets/images/home/home-section-1.png"
import imgHomeSectionTwo from "../assets/images/home/home-section-2.png"
import imgMarsBlur from "../assets/images/home/mars-blur.png"
import imgEarthBlur from "../assets/images/home/earth.png"
import imgVideoPlaceholder from "../assets/images/video-placeholder.png"
import imgPeopleOne from "../assets/images/home/people-img-1.png"
import imgPeopleTwo from "../assets/images/home/people-img-2.png"
import imgPeopleThree from "../assets/images/home/people-img-3.png"
import imgPeopleFour from "../assets/images/home/people-img-4.jpg"
import imgPeopleFive from "../assets/images/home/people-img-5-new.jpg"
import imgPeopleSix from "../assets/images/home/people-img-6-new.jpg"
import imgPeopleSeven from "../assets/images/home/people-img-7.jpg"
import imgHomeSatellite from "../assets/images/home/home-satellite-2.png"
import imgFactOne from "../assets/images/home/fact-1-w.jpg"
import imgFactTwo from "../assets/images/home/fact-2-w.jpg"
import imgFactThree from "../assets/images/home/fact-3-w.jpg"
import imgFactFour from "../assets/images/home/fact-4-w.jpg"
import imgFactFive from "../assets/images/home/fact-5-w.jpg"
import imgFactSix from "../assets/images/home/fact-6-w.jpg"
import imgFactSeven from "../assets/images/home/fact-7-w.jpg"
import imgFactEight from "../assets/images/home/fact-8-w.jpg"
import imgFactNine from "../assets/images/home/fact-9-w.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main>
      <HeroSection lang={"en"}></HeroSection>
      <div className="page-main-area">
        <section id="home-about" className="relative pt5-ns">
          <div className="large-container">
            <div className="standard-container transparent-bg flex flex-column flex-row-ns">
              <div className="w-100 w-60-ns pr5-ns pb5-ns z-index-10">
                <div>
                  <h2 className="relative">
                    About EMM
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                    The Emirates Mars Mission "Hope Probe" will be the first
                    probe to provide a complete picture of the Martian
                    atmosphere and its layers when it reaches the red planet’s
                    orbit in 2021. It will help answer key questions about the
                    global Martian atmosphere and the loss of hydrogen and
                    oxygen gases into space over the span of one Martian year.
                  </p>
                </div>
                <div className="negative-margin-right-150 pl5-ns pt5-ns">
                  <h3>OBJECTIVES</h3>
                  <p className="uk-text-bold text-light">
                    The
                    <span className="text-highlight-red">
                      {" "}
                      First Complete Picture{" "}
                    </span>
                    of the Martian Atmosphere.
                  </p>
                  <div className="flex flex-column mv4">
                    <div className="stacked-card flex justify-between items-center">
                      <div className="w-20 flex justify-center items-center">
                        <span
                          className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo uk-text-bold"
                          style={{ fontWeight: "500" }}
                        >
                          01
                        </span>
                      </div>
                      <div className="w-80">
                        <p>
                          Understand climate dynamics and the global weather map
                          through characterizing the lower atmosphere of Mars.
                        </p>
                      </div>
                    </div>
                    <div className="stacked-card flex justify-between items-center">
                      <div className="w-20 flex justify-center items-center">
                        <span
                          className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo"
                          style={{ fontWeight: "500" }}
                        >
                          02
                        </span>
                      </div>
                      <div className="w-80">
                        <p>
                          Explain how the weather changes the escape of Hydrogen
                          and Oxygen through correlating the lower atmosphere
                          conditions with the upper atmosphere.
                        </p>
                      </div>
                    </div>
                    <div className="stacked-card flex justify-between items-center">
                      <div className="w-20 flex justify-center items-center">
                        <span
                          className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo"
                          style={{ fontWeight: "500" }}
                        >
                          03
                        </span>
                      </div>
                      <div className="w-80">
                        <p>
                          Understand the structure and variability of Hydrogen
                          and Oxygen in the upper atmosphere, as well as
                          identifying why Mars is losing them into space.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 w-40-ns">
                <div className="animated-image-container">
                  <img src={imgHomeSectionOne} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="large-container relative">
            <img
              src={imgMarsBlur}
              className="absolute left-1 top-1 h-100 w-auto"
              alt=""
            />
            <div className="standard-container transparent-bg flex flex-column flex-row-ns">
              <div className="w-100 w-50-ns">
                <img
                  src={imgHomeSatellite}
                  className="negative-margin-left-150 home-satellite"
                  alt=""
                />
              </div>
              <div className="w-100 w-50-ns">
                <div className="animated-tab-head">
                  <div>
                    <h2 className="text-white">MISSION HIGHLIGHTS</h2>
                  </div>
                </div>
                <table className="uk-table uk-table-responsive uk-table-small home-custom-table">
                  <tbody className="text-white">
                    <tr>
                      <td className="text-highlight-brown">NAME</td>
                      <td>Emirates Mars Mission</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">PROBE NAME</td>
                      <td>Hope Probe</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">LAUNCHED</td>
                      <td>20th July 2020, 01:58 AM (Dubai Time)</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">LAUNCH LOCATION</td>
                      <td>Tanegashima​ Space Centre​, Japan</td>
                    </tr>
                    <tr>
                      <td className="text-highlight-brown">
                        CRUISING DISTANCE
                      </td>
                      <td>493.5 Million kms</td>
                    </tr>
                    {/* <tr>
                            <td className="text-highlight-brown">
                              CRUISING TIME
                            </td>
                            <td>
                              7 Months
                            </td>
                        </tr> */}
                    <tr>
                      <td className="text-highlight-brown">MISSION DURATION</td>
                      <td>One Martian Year (about 2 Earth years)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="large-container">
            <div className="standard-container transparent-bg">
              <div className="uk-inline">
                <video
                  src="https://emm-f548.kxcdn.com/home-launch-ar.mp4"
                  poster={imgVideoPlaceholder}
                  width="100%"
                  height="500px"
                  loop
                  playsinline
                  controls
                  uk-video
                ></video>
              </div>
            </div>
          </div>
        </section>
        <section id="why-mars" className="relative">
          <img
            src={imgEarthBlur}
            className="uk-overlay uk-position-left uk-postition-top h-auto w-50 pt6-ns"
            alt=""
          />
          <div className="large-container">
            <div className="standard-container transparent-bg">
              <div className="flex flex-column flex-row-ns">
                <div className="w-100 w-50-ns pl5-ns pt6">
                  <h2 className="relative">
                    WHY MARS?
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p
                    className="uk-text-lead text-highlight-brown font-nostromo"
                    style={{ fontWeight: "500" }}
                  >
                    WHY ARE WE EXPLORING MARS?
                  </p>
                  <p className="mv4">
                    The red planet has captured human imagination for centuries.
                    Now, we are at a junction where we know a great deal about
                    the planet, and we have the vision and technology to explore
                    further. Mars is an obvious target for exploration for many
                    reasons. From our pursuit to find extra-terrestrial life to
                    someday expand human civilization to other planets, Mars
                    serves as a long-term and collaborative project for the
                    entire human race.
                  </p>
                </div>
                <div className="w-100 w-50-ns">
                  <img
                    src={imgHomeSectionTwo}
                    alt=""
                    className="uk-align-right w-100 h-auto"
                  />
                </div>
              </div>
              <div className="ph4-ns relative pt5">
                <div
                  className="uk-position-relative uk-visible-toggle uk-light mt4"
                  data-tabindex="-1"
                  data-uk-slider="clsActivated: uk-transition-active; center: true "
                >
                  <div className="uk-position-relative">
                    <div className="uk-slider-container uk-light">
                      <ul className="uk-slider-items uk-grid uk-grid-small">
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns animated-text-trigger">
                            <div className="animated-image-container">
                              <img src={imgFactOne} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                Mars is the <br />
                                <span className="span-underline">
                                  {" "}
                                  second smallest planet{" "}
                                </span>{" "}
                                <br /> in the solar system
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactTwo} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                It’s the <br />{" "}
                                <span className="span-underline">
                                  fourth planet from the sun
                                </span>
                              </h2>
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactThree} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                <span className="span-underline">
                                  One day in Mars{" "}
                                </span>{" "}
                                <br /> is a little over 24 hours
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactFour} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                <span className="span-underline">
                                  One Martian year{" "}
                                </span>{" "}
                                <br /> is 687 days
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactFive} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                Mars is a <br />{" "}
                                <span className="span-underline">
                                  {" "}
                                  rocky planet
                                </span>
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactSix} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                Mars has
                                <span className="span-underline">
                                  {" "}
                                  two moons
                                </span>
                                <br />
                                Phobos and Demos
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactSeven} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                Mars has a <br />
                                <span className="span-underline">
                                  {" "}
                                  thin atmosphere
                                </span>
                              </h2>
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactEight} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                Mars appears red because of <br />
                                <span className="span-underline">
                                  {" "}
                                  iron minerals in its soil
                                </span>
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                        <li className="uk-width-1-1">
                          <div className="uk-panel pa4-ns">
                            <div className="animated-image-container">
                              <img src={imgFactNine} alt="" />
                            </div>
                            <div className="uk-position-center uk-text-center">
                              <h2 className="fact-slider-heading">
                                Mars has <br />
                                <span className="span-underline">
                                  {" "}
                                  no global magnetic field{" "}
                                </span>{" "}
                                <br /> today
                              </h2>
                              {/* <p uk-slider-parallax="x: 200,-200">Lorem ipsum dolor sit amet.</p> */}
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div className="uk-hidden@s uk-light uk-text-center">
                        <a
                          className="uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-previous
                          data-uk-slider-item="previous"
                        >
                          PREV
                        </a>
                        <a
                          className="uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-next
                          data-uk-slider-item="next"
                        >
                          NEXT
                        </a>
                      </div>

                      <div className="uk-visible@s">
                        <a
                          className="uk-position-center-left-out uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-previous
                          data-uk-slider-item="previous"
                        >
                          PREV
                        </a>
                        <a
                          className="uk-position-center-right-out uk-position-small custom-slide-nav"
                          href="#"
                          data-uk-slidenav-next
                          data-uk-slider-item="next"
                        >
                          NEXT
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 left-0 fact-text">FACTS</div>
              </div>
            </div>
          </div>
        </section>
        <section id="people">
          <div className="large-container pb6">
            <div className="standard-container">
              <div className="">
                <h2 className="relative uk-text-center">
                  EMM TEAM
                  <div
                    className="text-underline-heavy"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  ></div>
                </h2>
              </div>
              <div className="custom-slider ph4-ns" data-uk-slider>
                <div className="uk-position-relative">
                  <div className="uk-slider-container uk-light">
                    <ul className="uk-slider-items">
                      <li>
                        <img src={imgPeopleOne} alt="" />
                      </li>
                      <li>
                        <img src={imgPeopleTwo} alt="" />
                      </li>
                      <li>
                        <img src={imgPeopleThree} alt="" />
                      </li>
                      <li>
                        <img src={imgPeopleFour} alt="" />
                      </li>
                      {typeof imgPeopleFive !== "undefined" && (
                        <li>
                          <img src={imgPeopleFive} alt="" />
                        </li>
                      )}
                      {typeof imgPeopleSix !== "undefined" && (
                        <li>
                          <img src={imgPeopleSix} alt="" />
                        </li>
                      )}
                      <li>
                        <img src={imgPeopleSeven} alt="" />
                      </li>
                    </ul>
                  </div>

                  <div className="uk-hidden@s uk-light uk-text-center">
                    <a
                      className="uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-previous
                      data-uk-slider-item="previous"
                    >
                      <span data-uk-icon="icon: chevron-left"> </span> PREV
                    </a>
                    <a
                      className="uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-next
                      data-uk-slider-item="next"
                    >
                      NEXT <span data-uk-icon="icon: chevron-right"> </span>
                    </a>
                  </div>

                  <div className="uk-visible@s">
                    <a
                      className="uk-position-center-left-out uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-previous
                      data-uk-slider-item="previous"
                    >
                      <span data-uk-icon="icon: chevron-left"> </span> PREV
                    </a>
                    <a
                      className="uk-position-center-right-out uk-position-small custom-slide-nav"
                      href="#"
                      data-uk-slidenav-next
                      data-uk-slider-item="next"
                    >
                      NEXT <span data-uk-icon="icon: chevron-right"> </span>
                    </a>
                  </div>
                </div>

                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="partners">
            <div className="large-container">
              <div className="standard-container">
                <div className="">
                  <h2 className="relative uk-text-center">
                      PARTNERS
                    <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
                  </h2>
                </div>
                <div className="ph4-ns mv5" data-uk-slider>

                  <div className="uk-position-relative">

                      <div className="uk-slider-container uk-light">
                          <ul className="uk-slider-items uk-grid-small">
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                              <li>
                                  <img src={imgLogoHere} alt=""/>
                              </li>
                          </ul>
                      </div>

                      <div className="uk-hidden@s uk-light uk-text-center">
                          <div className="uk-position-small cusrsor-pointer custom-slide-nav" data-uk-slidenav-previous data-uk-slider-item="previous">PREV</div>
                          <div className="uk-position-small cusrsor-pointer custom-slide-nav"  data-uk-slidenav-next data-uk-slider-item="next">NEXT</div>
                      </div>

                      <div className="uk-visible@s">
                          <div className="uk-position-center-left-out uk-position-small cusrsor-pointer"  data-uk-slidenav-previous data-uk-slider-item="previous"></div>
                          <div className="uk-position-center-right-out uk-position-small cusrsor-pointer" data-uk-slidenav-next data-uk-slider-item="next"></div>
                      </div>

                  </div>
                </div>
              </div>
              
                    
            </div>
        </section>            */}
      </div>
    </main>
  </Layout>
)

export default IndexPage
